<!-- 约定数据标准 -->
<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="appoint-container">
    <header-area :stepActive="3" @getGroupInfo="getGroupInfo"></header-area>
    <div class="con borderTop" v-for="(item, index) in shopData" :key="index">
      <div class="conHd">
        <img v-if="item.shopLogo" :src="item.shopLogo" class="headerImg" /><span>{{item.shopName}}</span>
      </div>
      <div class="conBd">
        <div @click="() => { clickItemFun(itemTwo.type, index) }" :class="(item.type == 1 && itemTwo.type ==  'base') || (item.type == 2 && itemTwo.type ==  'register') || (item.type == 3 && itemTwo.type ==  'notice') ? 'con-item': 'con-item con-item-gery'" v-for="(itemTwo, indexTwo) in item.dataStands" :key="indexTwo">
          <div v-if="itemTwo.type == 'base'" class="con-hd">
            <span>【基础版】</span><el-radio :label="1" v-model="item.type">&nbsp;</el-radio>
          </div>
          <div v-if="itemTwo.type == 'register'" class="con-hd">
            <span>【专业版】</span><el-radio :label="2" v-model="item.type">&nbsp;</el-radio>
          </div>
          <div v-if="itemTwo.type == 'notice'" class="con-hd">
            <span>【旗舰版】</span><el-radio :label="3" v-model="item.type">&nbsp;</el-radio>
          </div>
          <div class="con-bd">
            <ul>
              <li><span>首响</span> <span class="bd-data">{{itemTwo['首响']}}s</span></li>
              <li><span>平响</span> <span class="bd-data">{{itemTwo['平响']}}s</span></li>
              <li><span>转换率</span> <span class="bd-data">{{itemTwo['转换率']}}s</span></li>
              <li><span>三分钟回复率</span> <span class="bd-data">{{itemTwo['三分钟回复率']}}%</span></li>
              <li><span>五分钟回复率</span> <span class="bd-data">{{itemTwo['五分钟回复率']}}%</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="conFt">
        <span class="ft-one">注:</span>
        <span>甲方因店铺流量增加导致实际日咨询量超过协议日咨询量10%，则乙方有权向甲方申请增加客服坐席，如甲方不同意增加，则因咨询量过大导致的数据下滑、客户投诉等问题，乙方不承担责任，且当月KPI按照正常打标计算。</span>
      </div>
    </div>
    <div class="wechat-group-container borderTop">
      <div class="wechat-switch">
        <el-switch v-model="wechatIsOpen" @change="() => { saveAgreedData('calculation') }"></el-switch>
        <span class="switch-title">配置服务对接群</span>
        <span class="switch-tip">完成客服服务内容与约定服务数据的对接与沟通。</span>
      </div>
      <div v-if="wechatIsOpen" class="wechat-content">
        <span>服务对接群单价</span>
        <el-input class="wechat-input" type="number" @blur="(val) => { chatGroup(val, 'price') }" v-model="serviceGroupPrice"></el-input>
        <!-- <el-input-number v-model="serviceGroupPrice" class="wechat-input" controls-position="right" @change="(val) => { chatGroup(val, 'price') }" :min="1"></el-input-number> -->
        <span>元/个，服务对接群数量</span>
        <el-input class="wechat-input" type="number" @blur="(val) => { chatGroup(val, 'num') }" v-model="serviceGroupNum"></el-input>
        <!-- <el-input-number v-model="serviceGroupNum" class="wechat-input" controls-position="right" @change="(val) => { chatGroup(val, 'num') }" :min="1"></el-input-number> -->
        <span>个，共</span>
        <span style="color: #f24433">{{(serviceGroupPrice * serviceGroupNum).toFixed(2)}}</span>
        <span>元。</span>
        <span class="wechat-tip">注：填写数量必须位整数。</span>
      </div>
    </div>
    <div v-loading="loading" element-loading-text="拼命加载中..." class="sixth">
      <el-button type="primary" @click="() => { saveAgreedData() }">下一步</el-button>
      <el-button plain class="sett-return" @click="() => { $router.push(`/quoted/setting-content?id=${$route.query.id}`) }">上一步：配置服务内容</el-button>
      <span class="sett-total">{{ (price).toFixed(2) }}</span>
      <span class="sett-rmb">¥</span>
      <span class="sett-money">配置费用：</span>
    </div>
  </div>
</template>

<script>
import { agreedData, saveAgreedData, calculationPrice } from "../../service/quoted.js"
import HeaderArea from "./components/header.vue"
export default {
  name: "appointStand",
  components: {
    HeaderArea
  },
  props: {},
  data() {
    return {
      loading: false,
      wechatIsOpen: false,
      serviceGroupPrice: 1,
      serviceGroupNum: 1,
      // groupResult: 1,
      radio: true,
      shopData: [],
      price: 0
    };
  },
  mounted() {
    this.agreedData()
    // this.calculationPrice()
    
  },
  methods: {
    getGroupInfo(info) {
      if (info && info.serviceGroupNum && info.serviceGroupPrice) {
        this.wechatIsOpen = true
        this.serviceGroupPrice = info.serviceGroupPrice
        this.serviceGroupNum = info.serviceGroupNum
      }
    },
    chatGroup(e, type) {
      let val = e.target.value;
      if (!val || val == 0) {
        if(type == "price") {
          this.serviceGroupPrice = 1
        } else {
          this.serviceGroupNum = 1
        }
        return this.$message.error("群单价或群数量不能为空！")
      }
      if(type == "price") {
        if (String(val).indexOf('-') > -1) { 
          this.serviceGroupPrice = 1
        } else {
          this.serviceGroupPrice = parseInt(val)
        }
      } else {
        if (String(val).indexOf('-') > -1) { 
          this.serviceGroupNum = 1
        } else {
          this.serviceGroupNum = parseInt(val)
        }
        // this.serviceGroupNum = parseInt(val)
      }
      this.saveAgreedData('calculation')
    },
    async calculationPrice () {
      this.loading = true
      let { data } = await calculationPrice({id: this.$route.query.id})
      this.loading = false
      this.price = data.price || 0
    },
    async saveAgreedData(type) { // 预定数据保存
      let shopData = JSON.parse(JSON.stringify(this.shopData))
      let newArr = []
      if (this.wechatIsOpen) {
        if (!this.serviceGroupPrice || !this.serviceGroupNum) {
          return this.$message.error("群单价或群数量不能为空！")
        }
      }
      shopData.map((item) => {
        if (item.dataStands) {
          item.dataStands.map((itemTwo)=>{
            if ((itemTwo.type == 'base' && item.type == 1) || (itemTwo.type == 'register' && item.type == 2) || (itemTwo.type == 'notice' && item.type == 3)) {
              newArr.push({id: item.id, baseServiceShopId: item.shopId, dataStands: JSON.stringify(itemTwo)})
            }
          })
        }
      })
      this.loading = true
      if (!this.wechatIsOpen) {
        await saveAgreedData({shopData: newArr})
      } else {
        await saveAgreedData({shopData: newArr, serviceGroupPrice: this.serviceGroupPrice, serviceGroupNum: this.serviceGroupNum})
      }
      this.loading = false
      // await saveAgreedData({shopData: newArr})
      if (type == 'calculation') {
        let { data } = await calculationPrice({id: this.$route.query.id})
        this.price = data.price || 0
      } else {
        this.$router.push(`/quoted/confirm?id=${this.$route.query.id}`)
      }
    },
    clickItemFun(type, i) { // 
      let radio = 0
      let shopData = [...this.shopData]
      switch(type) {
        case 'base': radio = 1; break;
        case 'register': radio = 2; break;
        case 'notice': radio = 3; break;
      }
      shopData.map((item, index)=>{
        if (index == i) {
          item.type = radio
        }
      })
      this.shopData = shopData
      this.saveAgreedData('calculation')
    },
    async agreedData() { // 约定数据详情
      let { data } = await agreedData({id: this.$route.query.id})
      this.shopData = data
      this.saveAgreedData('calculation')
    },
  },
};
</script>

<style lang="less" scoped>
.appoint-container {
  padding-bottom: 100px;
  .wechat-group-container {
    padding: 50px 165px;
    box-sizing: border-box;
    background: #fff;
    .wechat-content {
      display: flex;
      align-items: center;
      margin-left: 48px;
      position: relative;
      margin-top: 46px;
      .wechat-tip {
        font-size: 12px;
        color: #ababab;
      }
      .wechat-input {
        width: 80px;
        margin: 0 8px;
        height: 32px;
        line-height: 32px;
        // /deep/ .el-input__inner {
        //   padding-right: 17px;
        //   text-align: left;
        // }
        /deep/ .el-input__inner {
          padding-right: 2px;
          text-align: left;
        }
        /deep/.el-icon-arrow-down {
            transform: scale(.6);
          }
        /deep/.el-input-number__decrease {
          bottom: -1px;
          width: 16px;
          height: 16px;
          line-height: 16px;
          
          
        }
         /deep/ .el-icon-arrow-up {
            transform: scale(.6);
          }
        /deep/.el-input-number__increase {
          top: 3px;
          width: 16px;
          height: 15px;
          line-height: 15px;
         
        }
      }
      &::before {
        display: block;
        content: '*';
        color: #f00;
        position: absolute;
        left: -16px;
        margin: auto;
      }
    }
    .wechat-switch {
      display: flex;
      align-items: center;
      .switch-title {
        font-size: 18px;
        font-weight: 700;
        margin: 0 16px;
      }
      .switch-tip {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .borderTop {
    border-top: 16px solid #eee;
  }
  /deep/ .el-radio__label {
    opacity: 0;
    width: 0;
  }
  .header {
    box-sizing: border-box;
    padding: 25px 127px 32px 100px;
    border-bottom: 1px solid rgba(217, 217, 217, 100);
    .con-title {
      display: flex;
      .tit-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 330px;
        .address {
          // margin-top: 23px;
          font-size: 22px;
        }
        .el-icon-location {
          font-size: 22px;
          margin-right: 8px;
          color: #1890ff;
        }
        .name {
          font-size: 28px;
        }
        .status {
          box-sizing: border-box;
          background-color: #f2f9ff;
          color: #1890ff;
          border: 1px solid #1890ff;
          border-radius: 2px;
          padding: 4px 6px;
          margin-left: 24px;
          font-size: 14px;
          line-height: 16px;
        }
      }
      .tit-center {
        display: flex;
        .center-fir,
        .center-sec {
          display: flex;
          line-height: 42px;
          .center-fir-margin {
            margin-left: 72px;
          }
          .center-fir-third {
            // margin-right: 57px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 6px;
              left: 104px;
              display: inline-block;
              width: 1px;
              height: 72px;
              background: #bbb;
            }
          }
          .center-fir-con {
            display: inline-block;
            height: 42px;
          }
        }
        .sec-margin {
          margin-left: 110px;
        }
        .sec-width {
          width: 250px;
          text-align: left;
        }
        .center-sec-icon {
          margin-right: 16px;
        }
        .center-sec-con {
          display: inline-block;
          // margin-top: 23px;
          font-size: 20px;
          color: #333;
        }
      }
    }
  }
  .step {
    position: relative;
    box-sizing: border-box;
    margin-top: 48px;
    // height: 149px;
    // line-height: 149px;
  }
  .gap {
    height: 16px;
    background: #eee;
    margin: 32px 0 0;
  }
  .con {
    .conHd {
      display: flex;
      align-items: center;
      height: 75px;
      padding-left: 237px;
      background-color: #f6f9ff;
      text-align: left;
      border-bottom: 1px solid #b5d1ff;
      .headerImg {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 24px;
      }
      span {
        display: inline-block;
        font-size: 18px;
      }
    }
    .conBd {
      display: flex;
      justify-content: center;
      margin-bottom: 64px;
      .con-item-gery {
        border: 1px solid #d2d4d7 !important;
        .con-hd {
          border-bottom: 1px solid #d2d4d7 !important;
          background: #f9f9f9 !important;
        }
      }
      .con-item {
        cursor: pointer;
        width: 337px;
        height: 339px;
        margin-top: 32px;
        border: 1px solid #1890ff;
        border-radius: 4px;
        &:nth-child(-n + 2) {
          margin-right: 170px;
        }
        .con-hd {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px 0 24px;
          background: #f2f9ff;
          height: 75px;
          border-radius: 4px;
          border-bottom: 1px solid #1890ff;
          span {
            font-size: 18px;
          }
        }
        .con-bd {
          padding: 0 24px;
          ul {
            li {
              display: flex;
              justify-content: space-between;
              margin-top: 24px;
            }
          }
        }
        .bd-data {
          color: #1890ff;
        }
      }
    }
    .conFt {
      width: 1356px;
      padding-left: 244px;
      text-align: left;
      margin-bottom: 80px;
      .ft-one {
        margin-right: 24px;
      }
    }
  }
  .gap3 {
    height: 32px;
    background: #eee;
    margin: 32px 0;
  }
  .sixth {
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 101px;
    width: 100%;
    height: 84px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-top: 16px solid #eee;
  }
  .sett-money {
    margin-right: 15px;
  }
  .sett-rmb {
    color: #1890ff;
    margin-right: 10px;
  }
  .sett-total {
    color: #1890ff;
    font-size: 36px;
    margin-right: 24px;
  }
  .sett-status {
    color: #999999;
    font-size: 14px;
    margin-right: 24px;
  }
  .sett-return {
    border-color: #1890ff;
    color: #1890ff;
    margin-right: 16px;
  }
}
</style>
